import domReady from "./src/domReady";
import {captureException} from "@sentry/react";

domReady(()=>{
    const gaOnClickElements = Array.prototype.slice.call(document.querySelectorAll('[data-ga-on="click"]'));
    if(!!gaOnClickElements && gaOnClickElements.length){
        const handler = (e)=>{
            const el = e.currentTarget;
            const eventObj = {
                'event_category' : el.dataset?.gaEventCategory || '',
                'event_label' : el.dataset?.gaEventLabel || '',
            };
            try {
                gtag('event', 'click', eventObj);
            }
            catch(err) {
                captureException(err);
            }
            return true;
        };
        gaOnClickElements.forEach((el)=>{
            el.addEventListener('click', handler);
        });
    }
});